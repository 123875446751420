import BurgerMenu from "../Components/BurgerMenu";

function Navbar({ fixed, top }) {
  return (
    <div>
      <div className={top ? "navbar" : fixed ? "navbar-fixed" : "navbar-up"}>
        <div className="navbar-container">
          <a href="/">Ingridisidro</a>
          <div>
            <div className="navbar-categoroies">
              <div className="navbar-button-projects">
                <span style={{ cursor: "pointer" }}>Projects</span>
                <div className="drop-down">
                  <a className="drop-down-link" href="/conforama">
                    Conforama
                  </a>
                  <a className="drop-down-link" href="/birdsong">
                    Birdsong
                  </a>
                  <a className="drop-down-link" href="/signaturit">
                    Signaturit
                  </a>
                  <a className="drop-down-link" href="/signapp">
                    SignApp
                  </a>
                  <a className="drop-down-link" href="/closca">
                    Closca
                  </a>
                  <a className="drop-down-link" href="/hanaley">
                    Hanaley
                  </a>
                </div>
              </div>
              <a href="/about">About</a>
            </div>
          </div>
          <BurgerMenu />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
