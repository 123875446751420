import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import signappscroll1 from "../images/singapp/singappScroll1.png";
import signappscroll2 from "../images/singapp/singappScroll2.png";
import signappscroll3 from "../images/singapp/singappScroll3.png";
import signappscroll4 from "../images/singapp/singappScroll4.png";
import signappscroll5 from "../images/singapp/singappScroll5.png";
import signappscroll6 from "../images/singapp/singappScroll6.png";
import signappscroll7 from "../images/singapp/singappScroll7.png";
import signappscroll8 from "../images/singapp/singappScroll8.png";

function BlogCaroussel(props) {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let sections = gsap.utils.toArray(".blogCaroussel--panel-container");

    let scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".blogCaroussel--panel",
        pin: true,
        scrub: 1,
        end: "+=2000",
      },
    });

    // ScrollTrigger.getAll().forEach(t => t.kill());
    // ScrollTrigger.refresh();
  }, []);

  return (
    <section className="blogCaroussel">
      <div className="blogCaroussel--panel">
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll1}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll2}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll3}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll4}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll5}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll6}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll7}
          />
        </div>
        <div className="blogCaroussel--panel-container">
          <img
            className="blogCaroussel--panel-container_image"
            src={signappscroll8}
          />
        </div>
        <div className="blogCaroussel--panel-container"></div>
      </div>
    </section>
  );
}

export default BlogCaroussel;
