import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Views/Home";
import About from "./Views/About";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Load from "./Views/Load";
import Birdsong from "./Views/Birdsong";
import Conforama from "./Views/Conforama";
import Closca from "./Views/Closca";
import Signapp from "./Views/Signapp";

function App() {
  const [showPage, setShowPage] = useState(true); //turn false if you want the load animation.
  const [showMenu, setShowMenu] = useState(false);
  const [top, setTop] = useState(true);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    if (window.scrollY === 0) {
      setTop(true);
    } else {
      setTop(false);
    }
  }, [offsetY]);

  window.onscroll = function (e) {
    if (window.oldScroll > window.scrollY) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
    window.oldScroll = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.addEventLinstener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPage(true);
    }, 3000);
  }, []);

  return (
    <Router>
      {!showPage && <Load />}
      {showPage && (
        <div>
          <Navbar fixed={showMenu ? true : false} top={top} />
          <Routes>
            <Route path="*" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/birdsong" element={<Birdsong />} />
            <Route exact path="/conforama" element={<Conforama />} />
            <Route exact path="/closca" element={<Closca />} />
            <Route exact path="/signapp" element={<Signapp />} />
          </Routes>
          <Footer/>
        </div>
      )}
    </Router>
  );
}

export default App;
