import closcaheader from "../images/closca/closca-header.png";
import darkTheme from "../images/closca/dark-theme.png";
import wireframe from "../images/closca/wireframe.png";
import closca1 from "../images/closca/closca-1.png";
import closca2 from "../images/closca/closca-2.png";
import final from "../images/closca/Final.png";
import finalMobile from "../images/closca/final-mobile.png";
import { BsArrowRight } from "react-icons/bs";

function Closca() {
  return (
    <div className="project-page-container">
      <div className="prj-header-img">
        <img src={closcaheader} alt="conf-header" />
      </div>
      <div className="prj-header-img-mobile">
        <img src={closcaheader} alt="conf-mobile-header" />
      </div>
      <div className="prj-info-head">
        <h1>
          Closca, productos con personalidad que son el cimiento de su ecommerce
        </h1>
        <p>
          Closca, es una marca de productos de diseño. El core businesses son
          cascos y botellas urbanitas, modernas y sostenibles. Ganadores de
          varios premios de diseño de producto son una marca reconocida en
          España. Para ellos, la estética es muy importante, como transmitir su
          marca, la forma de comunicarse con sus usuarios y la forma en la que
          muestran sus productos. Por todo ello, se inició el proyecto con
          Shopify, para conseguir un resultado moderno y personalizado pero que
          Closca pudiese gestionar con facilidad.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>SITEMAP & INFORMATION ARCHITECTURE</h3>
        </div>
        <h2>
          Coordinar objetivos entre todos los stakeholders implicados en el
          proyecto.
        </h2>
        <p>
          Retos:
          <br />
          <br />
          · Conseguir un buen posicionamiento SEO sin perder los valores de
          marca.
          <br />
          <br />
          · Muy pocas categorías de producto pero con muchas variantes, todas
          con su propio protagonismo.
          <br />
          <br />
          · Priorizar la parte comercial sin olvidar todo el storytelling de
          marca.
          <br />
          <br />
          Solución:
          <br />
          <br />
          Después de muchas reuniones con todos los departamentos implicados y
          con el cliente llegamos a un Sitemap definitivo, con todos los nombres
          de las categorías, priorizamos las variantes de producto como si fuese
          un producto en sí.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>USER PERSONA</h3>
        </div>
        <h2>User-centered design: diseñando con Adrián en mente</h2>
        <p>
          Después de un exhaustivo analisis del mercado creamos a nuestro
          User-Persona, Adrià, para tenerlo siempre presente en todo el proceso.
        </p>
      </div>
      <div className="site-map">
        <img src={darkTheme} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>WIREFRAMES</h3>
        </div>
        <h2>
          Crear un grid para las categorías de producto trendy, personalizado y
          funcional.
        </h2>
        <p>
          Retos:
          <br />
          <br />
          · Diseñar una página de categría con productos destacados y un grid
          alejado del más convencional.
          <br />
          <br />
          · Compaginar este grid personalizado con las funciones de Shopify con
          el equipo de Tech.
          <br />
          <br />
          · Que esta página de categría funcione tanto en mobile como en
          desktop.
          <br />
          <br />
          · Diseñar una navegación lateral.
          <br />
          <br />· No olvidar todo el storytelling de cada producto en la página
          de producto. Saber combinar la parte más comercial con la parte más
          emocional de la marca.
        </p>
      </div>
      <div className="site-map">
        <img src={wireframe} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>HI-FIDELITY WIREFRAMES</h3>
        </div>
        <h2>
          Menos es más. Closca minimal, funcional y urbanita. Diseñar un
          ecommerce con personalidad.
        </h2>
        <p>
          Otro de los grandes retos fue diseñar un ecommerce en escala de
          grises, sin color de interacción más allá del negro. Grande
          protagonismo de las fotografías, detalles que dan personalidad,
          innovación en la estructura y jerarquización de la información.
        </p>
      </div>
      <div className="prj-info-head-2">
        <h4>CATEGORY PAGE. MOBILE & DESKTOP</h4>
      </div>
      <div className="blog-map">
        <img src={closca1} alt="cf-site-map" />
      </div>
      <div className="prj-info-head-2">
        <h4>CLOSCA BLOG. MOBILE & DESKTOP</h4>
      </div>
      <div className="blog-map">
        <img src={closca2} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>LEARNINGS</h3>
        </div>
        <ul>
          <li>Aprender de las complicaciones técnicas de Shopify.</li>
          <li>
            Coordinar y compartir ideas y conocimiento entre stakeholders.
            Aprender a priorizar, a ceder y a defender las best practices de
            UX·UI.
          </li>
          <li>Learn to work with a user testing, priorizaze the feedback.</li>
          <li>
            Aprender a ser innovadora y creativa con pocos elementos con los que
            jugar. Proponer estructuras diferentes a las más estandarizadas, que
            en este caso, tienen sentido y funcionan teniendo en mente al
            User-Persona de Closca.
          </li>
          <li>Sometimes less is more.</li>
        </ul>
      </div>
      <div className="final-map">
        <img src={final} alt="cf-site-map" />
        <div className="centered">
          <h1>Inspire the change.</h1>
          <span>
            Closca, productos con personalidad que son el cimiento de su
            ecommerce
          </span>
          <div>
            <a href="https://closca.com/es">Watch the Closca website here</a>
            <BsArrowRight />
          </div>
        </div>
      </div>
      <div className="final-map-mobile">
        <div className="centered-mobile">
          <h1>Inspire the change.</h1>
          <span>
            Closca, productos con personalidad que son el cimiento de su
            ecommerce
          </span>
          <div>
            <a href="https://closca.com/es">Watch the Closca website here</a>
            <BsArrowRight />
          </div>
        </div>
        <img src={finalMobile} alt="cf-site-map" />
      </div>
    </div>
  );
}

export default Closca;
