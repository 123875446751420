function Load() {
  return (
    <div className="main-load">
      <div className="circle"></div>
      <div className="fixed-circle">
        <h4>Ingridisidro</h4>
      </div>
    </div>
  );
}

export default Load;
