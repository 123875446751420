import bsHeader from "../images/birsong/header-birdsong.png";
import bsSiteMap from "../images/birsong/Birdsong-Sitemap.png";
import bsBenchMarking from "../images/birsong/benchmarking-birdsong.png";
import bsUserPersona from "../images/birsong/userpersona-birsong.png";
import bsWireframes from "../images/birsong/wireframes-birdsong.png";
import form1 from "../images/birsong/muntatge 01/Vector 59.png";
import form2 from "../images/birsong/muntatge 01/Lines-1 2.png";
import bsMobile from "../images/birsong/muntatge 01/Group 619.png";
import bsCategories from "../images/birsong/muntatge 01/Category/All Clothing v2.png";
import mobileHeader from "../images/birsong/mobile-header.png";
import bsBottom from "../images/birsong/bottom.png";
import { BsArrowRight } from "react-icons/bs";
import mobile1 from "../images/birsong/mobile1.png";
import mobile2 from "../images/birsong/mobile2.png";
import mobile3 from "../images/birsong/mobile3.png";
import mobile4 from "../images/birsong/mobile4.png";
import mobile5 from "../images/birsong/mobile5.png";
import finalMobile from "../images/birsong/finalMobile.png";

function Birdsong() {
  return (
    <div className="project-page-container">
      <div className="prj-header-img">
        <img src={bsHeader} alt="bird-header" />
      </div>
      <div className="prj-header-img-mobile">
        <img src={mobileHeader} alt="bird-mobile-header" />
      </div>
      <div className="prj-info-head">
        <h1>
          Birdsong wants to update their ecommerce platform, transforming the
          look and feel and to position the brand as an ethical and sustainable
          brand.
        </h1>
        <p>
          Birdsong is an ethical and sustainable fashion brand recognised for
          it’s eye-catching and unique designs, and dedication to social
          entreprise. The brand, frequently listed in Vogue, Elle, and other
          fashion staples as one of the up and coming ethical fashion brands in
          London, put’s their makers first even in a difficult global economy.
          This translates to honoring worker’s rights, celebrating joy, and
          creating with empathy.
        </p>
        <p>
          I was the lead designer on the project, responsible for the interface
          and experience in product and website. We’re 3 roles in this project:
          the Brand strategist, the Project Manager and me.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>SITEMAP</h3>
        </div>
        <h2>
          The importance of position Birdsong as an ethical and sustainable
          brand
        </h2>
        <p>
          Challenges:
          <br />
          <br />
          · User Experience: Create an attractive user experience.
          <br />
          <br />
          · CRO: Move from a conversion rate of 0.73% to 2%.
          <br />
          <br />
          · Brand Loyalty: Encourage retention and increase return rates from
          17.28% to 30%.
          <br />
          <br />
          · SEO: Position as "UK ethical clothing brands".
          <br />
          <br />
          · Storytelling: Improve communication of USP, vision and brand impact
          through visual and written content.
          <br />
          <br />
          The buying process was confusing. In this case we re-thought the
          categories and the flow that the user would follow to make a purchase.{" "}
          <br />
          <br />
          There was a lack of content pages where Birdsong had the opportunity
          to tell the story of why it was a sustainable and ethical brand. In
          this sense we wanted to highlight a section in the navigation about
          Sustainability where we would find the pages of: Ethical clothing
          (highlighting an ethical production chain, focusing on the product),
          Meet our makers (production and manufacturing made in the UK with
          workers at risk of exclusion) and the Materials (fully sustainable
          fabrics). <br />
          <br />
          Finally, we gave a lot of importance to the Blog as Birdsong is a very
          combative and intellectual brand, which share whith users an
          attractive content and allows it to create a strong engagement.
        </p>
      </div>
      <div className="site-map">
        <img src={bsSiteMap} alt="bs-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>BENCHMARKING</h3>
        </div>
        <h2>How to position the brand both visually and structurally.</h2>
        <p>
          Analyze the ecommerce of competitors, look for how we can
          differentiate ourselves, successes and mistakes that competitors make
          on their websites. Search for inspiration and references. What we
          found:
          <br />
          <br />
          - In general the world of sustainable and ethical fashion uses a very
          simple structure on their websites. They position themselves through
          powerful claims and inspiring photographs.
          <br />
          <br />
          - Use of neutral colors - Good use of headings, paragraphs,
          highlighted text and photographs to make the longer text more dynamic
          <br />
          <br />
          - Use of infographics to reinforce the story. Very visual, simple and
          easy to understand. Provides transparency and credibility.
          <br />
          <br />
          - Good use of slogans and smart labelling, a mix between inspirational
          and commercial.
          <br />
          <br />
          Main brands we analyzed: Arket, Ecoalf, Everlane, Knowtheorigin.
        </p>
      </div>
      <div className="benchmarking-map">
        <img src={bsBenchMarking} alt="bs-benchmarking" />
      </div>
      <div className="mobile-img">
        <img src={mobile1} alt="bs-benchmarking" />
      </div>
      <div className="prj-info-container" style={{ marginTop: "0" }}>
        <div>
          <h3>USER PERSONA</h3>
        </div>
        <h2>User-centered design: design with Maiara in mind</h2>
        <p>
          After exhaustively analysing Birdsong's competitors, we created a User
          Persona to start the Ideation process, always keeping Maiara in mind.
        </p>
      </div>
      <div className="user-persona">
        <img src={bsUserPersona} alt="bs-user-persona" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>WIREFRAMES</h3>
        </div>
        <h2>Mobile first.</h2>
        <p>
          Birdsong's previous ecommerce was not responsive and presented many
          problems in the mobile version. Above all, this was reflected in the
          commercial flow of the website. That's why we planned the project
          taking into account that the commercial pages should be very clear,
          simple and very sales oriented in the mobile version.
          <br />
          <br />
          We approached the project from the beginning designing the wireframes
          first in mobile and then in desktop. We prioritized functionality in
          the commercial pages and played with more complex and original
          structures that conveyed the essence of Birdsong in the content pages.
          <br />
          <br />
          All the storytelling of the brand, the values, had to be transmitted
          throughout the web but especially on the content page. The Birdsong
          user has the need to find this information and must find it in an easy
          and intuitive way.
        </p>
      </div>
      <div className="user-persona">
        <img src={bsWireframes} alt="bs-user-persona" />
      </div>
      <div className="mobile-img" style={{ width: "70%" }}>
        <img src={mobile2} alt="bs-benchmarking" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>PROTOTYPE</h3>
        </div>
        <h2>Prototyping the wireframes to focus that everything works</h2>
        <p>
          For me it is very important the prototype of a project, it is good and
          necessary to take it into account from the wireframes. This way, you
          can pay attention about all the dropdowns, pop-us, flows, buttons...
          and you always have the Maiara's purpose: to make a easy purchase and
          to find information about the sustainability and ethics of both the
          company and the product.
          <br />
          <br />
          Another positive point of prototyping is that you often work with
          people who are not used to the UX/UI language and prototyping makes it
          easier for them to understand. Wireframes, no matter how hard you work
          on them, are not a simple document to understand for many clients and
          people involved with the project. Thus, prototyped wireframes will
          always help them to understand and imagine how the project works.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>HI-FIDELITY WIREFRAMES</h3>
        </div>
        <h2>
          Design with the values of femininity, ecology, creativity and justice
          in mind.
        </h2>
        <p>
          It was not easy to find a mix of simplicity and the values the brand
          wanted to represent. Nor was it easy to do so through the design
          complications posed by Shopify and some Ecommerce Apps. Creating a
          soft style but at the same time with a strong personality.
          <br />
          <br />
          We started to define the fonts we were going to use, text sizes, text
          hierarchies, buttons... all these elements we wanted to give them a
          simple and easy to understand look for any user. Birdsong already has
          a brand with very representative colours and shapes, so we played with
          them to give this original and differentiating touch to the website.
          In a way, this way we also fulfilled Maiara's objectives: to make an
          easy purchase through the web and to find more detailed information
          about the origin of the product and its manufacture.
          <br />
          <br />
          With all this, we ended up creating the result that you will now see.
          As we were designing, we also encountered a problem:
          <br />
          <br />
          - How were we going to change the headers and customise them?
          <br />
          <br />
          The solution: we prepared a Figma with several samples of possible
          headers with different shapes and colours so that Birdsong could adapt
          it before and test how it looked.
        </p>
      </div>
      <div className="form-container-parent">
        <div className="form-container">
          <img
            style={{
              width: "30%",
              position: "absolute",
              left: "15%",
              top: 7750,
              zIndex: "0",
            }}
            src={form1}
            alt={form1}
          />
          <img
            style={{
              position: "absolute",
              width: "35%",
              left: "27%",
              top: 7920,
              zIndex: "0",
            }}
            src={form2}
            alt={form2}
          />
        </div>
        <div
          className="category-container"
          style={{ zIndex: "1", position: "relative" }}
        >
          <div>
            <h4>CATEGORY AND PRODUCT PAGE</h4>
            <p>
              All pages in this category are designed with a sales and
              conversion focus. These pages are 80% commercial, 20% conceptual,
              with some space for content.
              <br />
              <br />
              We focused all the inspirational content in the header of this
              page, original, organic, joyful and trendy.
            </p>
            <img
              src={bsMobile}
              alt={bsMobile}
              style={{ width: "155%", marginLeft: "-100px" }}
            />
          </div>
          <div>
            <img
              src={bsCategories}
              alt={bsCategories}
              style={{ width: "180%" }}
            />
          </div>
        </div>
      </div>
      <div className="prj-info-container-mobile">
        <h4 style={{ fontSize: "16px" }}>CATEGORY AND PRODUCT PAGE</h4>
        <p>
          All pages in this category are designed with a sales and conversion
          focus. These pages are 80% commercial, 20% conceptual, with some space
          for content.
          <br />
          <br />
          We focused all the inspirational content in the header of this page,
          original, organic, joyful and trendy.
        </p>
      </div>
      <div className="mobile-img" style={{ width: "90%" }}>
        <img src={mobile3} alt="bs-benchmarking" />
      </div>
      <div className="mobile-img" style={{ width: "90%", marginTop: "0" }}>
        <img src={mobile4} alt="bs-benchmarking" />
      </div>
      <div className="prj-info-container-mobile" style={{ marginTop: "0" }}>
        <h4 style={{ fontSize: "16px" }}>CATEGORY AND PRODUCT PAGE</h4>
        <p>
          All pages in this category are designed with a sales and conversion
          focus. These pages are 80% commercial, 20% conceptual, with some space
          for content.
          <br />
          <br />
          We focused all the inspirational content in the header of this page,
          original, organic, joyful and trendy.
        </p>
      </div>
      <div className="mobile-img" style={{ width: "90%" }}>
        <img src={mobile5} alt="bs-benchmarking" />
      </div>
      <div className="final-map" style={{ marginTop: "100px" }}>
        <img src={bsBottom} alt="cf-site-map" />
        <div className="centered">
          <h1>Fashion as an ally for change.</h1>
          <span>
            An inspiring, motivating project where all team members have learned
            not only on a professional level, but also on a human level. The
            result of the project has been possible thanks to the trust and the
            great team of Birdsong.
          </span>
          <div>
            <a href="https://birdsong.london">
              Watch the Birdsong website here
            </a>
            <BsArrowRight />
          </div>
        </div>
      </div>
      <div className="final-map-mobile">
        <img src={finalMobile} alt="cf-site-map" />
        <div className="centered-mobile-bs">
          <h1>Fashion as an ally for change.</h1>
          <span>
            An inspiring, motivating project where all team members have learned
            not only on a professional level, but also on a human level. The
            result of the project has been possible thanks to the trust and the
            great team of Birdsong.
          </span>
          <div>
            <a href="https://birdsong.london">
              Watch the Birdsong website here
            </a>
            <BsArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Birdsong;
