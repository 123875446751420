import selfie from "../images/selfie.png";
import { BsArrowRight } from "react-icons/bs";
import cv from "../images/CV-IngridIsidro-2023.pdf";

function About() {
  return (
    <div className="about">
      <h1>About me</h1>
      <div className="about-content">
        <div className="about-content-left">
          <p>
            I’m Ingrid a product designer from Barcelona👋🏼. My passion is
            designing digital tools that empower people and augment their
            abilities in order to achieve their goals. I believe in user-centred
            design and simplistic expression. I feel confidence working in
            multidisciplinary teams sharing my work and attending collaborations
            and constructive criticism.
          </p>
          <p>
            +4 of experience as a designer. My background in graphic design and
            journalism makes me feel confidence to transform the insights of a
            research in a visual way.
          </p>
          <p>
            I am a team-oriented person, problem solving, adaptative curious and
            empathetic designer.
          </p>
        </div>
        <div className="about-content-right">
          <div>
            <img src={selfie} alt="selfie" />
          </div>
          <form method="get" action={cv}>
            <button type="submit">
              Download CV
              <BsArrowRight />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default About;
