import { BsArrowRight } from "react-icons/bs";
import projectInfo from "../projects.json";
import ProjectCard from "../Components/ProjectCard";
import birdsongImg from "../images/birsong/birdsong-home.png";
import conforamaImg from "../images/conforama/home-picture-conforama.png";
import closcaImg from "../images/closca/closcaImg.png";
import singappImg from "../images/singapp/singappImg.png";
import signaturitImg from "../images/signaturit/signaturitImg.png";
import hanaleyImg from "../images/hanaley/hanaleyImg.png";

function Home() {
  return (
    <div className="home">
      <div className="top-container">
        <h4>Hello! I’m Ingrid a product designer from Barcelona.</h4>
        <h1>I design mindful user experiences & beautiful interfaces</h1>
        <a href="/about">
          More about me
          <BsArrowRight />
        </a>
        <div className="link-to"></div>
      </div>
      <div className="projects-container">
        <div className="column-1">
          <ProjectCard info={projectInfo[0]} img={conforamaImg} />
          <ProjectCard info={projectInfo[2]} img={closcaImg} />
          <ProjectCard info={projectInfo[4]} img={signaturitImg} />
        </div>
        <div className="column-2">
          <ProjectCard info={projectInfo[1]} img={birdsongImg} />
          <ProjectCard info={projectInfo[3]} img={singappImg} />
          <ProjectCard info={projectInfo[5]} img={hanaleyImg} />
        </div>
      </div>
    </div>
  );
}

export default Home;
