import BlogCaroussel from "../Components/Carrousel";
import CarrouselMobile from "../Components/CarrouselMobile";

import signappHeader from "../images/singapp/signapp-header.png";
import signapp1 from "../images/singapp/signapp-1.png";
import signapp2 from "../images/singapp/signapp-2.png";
import signapp3 from "../images/singapp/signapp-3.png";
import signapp4 from "../images/singapp/signapp-4.png";

function Signapp() {
  return (
    <div className="project-page-container">
      <div className="prj-header-img">
        <img src={signappHeader} alt="conf-header" />
      </div>
      <div className="prj-header-img-mobile">
        <img src={signappHeader} alt="conf-mobile-header" />
      </div>
      <div className="prj-info-head">
        <h1>Design for everywone. Prioritize accessibility.</h1>
        <p>
          Signapp is the mobile application of the non-profit association ACILS,
          association of sign language interpreters.
          <br />
          <br />
          The goal of this mobile app is to be the MVP of a great sign language
          dictionary.Through videos, users will be able to search and see how to
          say a word in sign language.
          <br />
          <br />
          The target of this application will be entire population, therefore,
          we face the challenge of making a fully accessible App where every
          user feels comforatble using it.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>ACCESIBLE DESIGN</h3>
        </div>
        <h2>Look for principles of accessible design</h2>
        <p>
          “While inclusive design and accessibility are not the same thing,
          accessibility is one of the primary outcomes of an effective inclusive
          design process. Accessibility on its own, though, will leave out large
          sections of the population who don’t have a defined, legally
          recognized disability but may have issues interacting with interfaces
          based on particular circumstances in their environment. Inclusive
          design actively seeks out those diverse situations, which include
          disabilities, and aims to address them.” says Toptal about it.
          <br />
          <br />
          After reading many articles, official guides and interviews the
          insights that we keep to apply them in the project:
          <br />
          <br />
          · Don’t use color as the only visual means of conveying information.
          Use color to highlight or complement what is already visible.
          <br />
          <br />
          · Ensure sufficient contrast between text and its background. (Using a
          Figma Pluguin)
          <br />
          <br />
          · Meet the humble autocomplete typeahead.
          <br />
          <br />
          · The use of icons to make a design more intuitive.
          <br />
          <br />
          · Audio content must contain subtitles.
          <br />
          <br />
          · Prioritize simplicity and noticeability. Easy and consistent
          interactions.
          <br />
          <br />
          Large, understandable and distinctive buttons · Content with images
          and videos.
          <br />
          <br />
          · Readable font, correct text size
          <br />
          <br />
          Plugin of the Figma Design program that checks the color contrast
          ratio of all visible text in a frame and evaluates whether it meets
          the AA and / or AAA compliance level of WCAG (Web Content
          Accessibility Guidelines).
        </p>
      </div>
      <div className="site-map">
        <img src={signapp1} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>INFORMATION ARCHITECTURE AND USER FLOW</h3>
        </div>
        <h2>
          The challenge of including a lot of information in a navigation that
          is as simple as possible
        </h2>
        <p>
          An exhaustive job on how to place all the necessary information on the
          different screens. Simplify the flows that the user must follow to
          reach their goal.
        </p>
      </div>
      <div className="site-map">
        <img src={signapp2} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>LOW-FI</h3>
        </div>
        <p>
          Detail all the essential elements, all the features that the user will
          find on each screen. Detail all the objectives of each page of the App
        </p>
      </div>
      <BlogCaroussel />
      <CarrouselMobile />
      <div className="prj-info-container">
        <div>
          <h3>HI-FIDELITY</h3>
        </div>
        <p>
          Challenge: Design for everyone. Transform a legible, intuitive design,
          with contrasting colors, with text size standards, to a current,
          simple and functional design. We present two design options: First
          option: Grayscale design, use of color in the background of
          categories. Use of black outlined icons. A lot of weight of the videos
          on all screens. Simple and intuitive search screens.
        </p>
      </div>
      <div className="site-map">
        <img src={signapp3} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <p>
          Second option: Grayscale design use of color flat icons, the only
          element that breaks with the Grayscale and the interaction color. A
          lot of weight of the videos on all screens. Simple and intuitive
          search screens.
        </p>
      </div>
      <div className="site-map">
        <img src={signapp4} alt="cf-site-map" />
      </div>
      <div className="prj-info-container" style={{ marginBottom: "50px" }}>
        <div>
          <h3>LEARNINGS</h3>
        </div>
        <ul>
          <li>
            Learn a lot about the Accesible design. A knowledge I'm going to use
            in all the projects I work with.
          </li>
          <li>
            Some technical aspects that can help me in the design process to
            check the accesability like the Figma pluguin.
          </li>
          <li>
            Learn about the technical aspects of IOS and Android standards.
          </li>
          <li>
            Learn to design a very simple thing like a dictionary. It can be
            very easy at the first time but it’s more challenging.
          </li>
          <li>Design for everyone.</li>
        </ul>
      </div>
    </div>
  );
}

export default Signapp;
