import { BsArrowRight } from "react-icons/bs";

function Footer() {
  return (
    <div className="footer">
    <div className="footer-container">
      <div className="footer-container-left">
        <div>
          <h1>Let's work together</h1>
          <a href="/about">
            Write an email
            <BsArrowRight />
          </a>
        </div>
        <h5>© Ingrid Isidro Portfolio 2022.</h5>
      </div>
      <div className="footer-container-right">
        <h3>PROJECTS</h3>
        <ul>
          <li>
            <a className="drop-down-link" href="/conforama">
              Conforama Checkout
            </a>
          </li>
          <li>
            <a className="drop-down-link" href="/birdsong">
              Birdsong ecommerce
            </a>
          </li>
          <li>
            <a className="drop-down-link" href="/signapp">
              SignApp, Mobile product
            </a>
          </li>
          <li>
            <a className="drop-down-link" href="/closca">
              Closca ecommerce
            </a>
          </li>
          <li>
            <a className="drop-down-link" href="/signaturit">
              Signaturit
            </a>
          </li>
          <li>
            <a className="drop-down-link" href="/hanaley">
              Hanaley
            </a>
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
}

export default Footer;
