import React, { useState } from "react";
import button from "../images/button.png";

function ProjectCard(props) {
  const [isShown, setIsShown] = useState(false);
  const projectInfo = props.info;
  const img = props.img;
  const comingSoon = projectInfo.comingSoon;

  return (
    <div className="project-card">
      <a
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        href={projectInfo.urlName}
        className="project-card-image"
      >
        {isShown && !comingSoon &&(
          <div className="project-card-hover">
            <img className="card-icon" src={button} alt={button} />
          </div>
        )}
        {isShown && comingSoon && (
          <div className="project-card-hover">
            <h2>Coming soon</h2>
          </div>
        )}
        {!isShown && (
          <div>
            <img src={img} alt={img} />
          </div>
        )}
      </a>
      <span>{projectInfo.type}</span>
      <h2>{projectInfo.name}</h2>
      <p>{projectInfo.shortDescription}</p>
    </div>
  );
}

export default ProjectCard;
