import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import burgerIcon from "../images/burger-icon.png";
import xIcon from "../images/X.png";

function BurgerMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);
  const [closed, setClosed] = useState({});
  const [menuStyle, setMenuStyle] = useState({})
  const [enableProjects, setEnableProjects] = useState(false);

  const handleOpenBurgerMenu = () => {
    setShowMenu(true);
    setMenuStyle({ animation: "openBurgerMenu 0.5s"});
  };

  const handleCloseBurgerMenu = () => {
    setMenuStyle({ animation: "closeBurgerMenu 0.3s"});
    setTimeout(() => {
      setShowMenu(false);
    }, 200);
    setOpenProjects(false);
  };

  const handleProjectsDropdown = () => {
    console.log(openProjects);
    console.log(closed);

    if (!enableProjects) {
      setEnableProjects(!enableProjects);
    }
    if (!openProjects) {
      setOpenProjects(true);
      setClosed({ animation: "openBurgerProjectsDropdown 0.3s", height: 200 });
    } else {
      setClosed({ animation: "closeBurgerProjectsDropdown 0.3s", height: 0 });
      setTimeout(() => {
        setOpenProjects(false);
      }, 100);
    }
  };

  return !showMenu ? (
    <div className="mobile-menu">
      <button onClick={handleOpenBurgerMenu}>
        <img className="burger-menu-icon" src={burgerIcon} alt="menu-icon" />
      </button>
    </div>
  ) : (
    <div className="burger-menu" style={menuStyle}>
      <div className="burger-menu-top">
        <a style={{ color: "white" }} href="/">
          ingridisidro
        </a>
        <button onClick={handleCloseBurgerMenu}>
          <img src={xIcon} alt="close-icon" />
        </button>
      </div>
      <div className="burger-menu-projects">
        <button
          onClick={handleProjectsDropdown}
          className="burger-menu-projects-button"
        >
          <p>Projects</p>
          {openProjects ? <BsChevronUp /> : <BsChevronDown />}
        </button>
        {openProjects && (
          <div style={closed}>
            <a href="/conforama">Conforama</a>
            <a href="/birdsong">Birdsong</a>
            <a href="/signaturit">Signaturit</a>
            <a href="/signapp">SignApp</a>
            <a href="/closca">Closca</a>
            <a href="/hanaley">Hanaley</a>
          </div>
        )}
      </div>
      <div className="burger-menu-about-button">
        <a href="/about">About</a>
      </div>
    </div>
  );
}

export default BurgerMenu;
