import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import signappscroll1 from "../images/singapp/singappScroll1.png";
import signappscroll2 from "../images/singapp/singappScroll2.png";
import signappscroll3 from "../images/singapp/singappScroll3.png";
import signappscroll4 from "../images/singapp/singappScroll4.png";
import signappscroll5 from "../images/singapp/singappScroll5.png";
import signappscroll6 from "../images/singapp/singappScroll6.png";
import signappscroll7 from "../images/singapp/singappScroll7.png";
import signappscroll8 from "../images/singapp/singappScroll8.png";

const CarrouselMobile = () => {
  return (
    <div className="carousel-mobile">
      <AwesomeSlider>
        <div className="carousel-mobile-content">
          <img src={signappscroll1} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll2} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll3} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll4} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll5} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll6} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll7} alt={"carrousel"} />
        </div>
        <div className="carousel-mobile-content">
          <img src={signappscroll8} alt={"carrousel"} />
        </div>
      </AwesomeSlider>
    </div>
  );
};

export default CarrouselMobile;
