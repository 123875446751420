import conforamaHeader from "../images/conforama/conforama-header.png";
import conforamaBasket from "../images/conforama/conforama-basket.png";
import conforamaHeuristic from "../images/conforama/conforama-heuristic.png";
import conforamaUserflow from "../images/conforama/conforama-userflow.png";
import conforamaBM from "../images/conforama/conforama-benchmarking.png";
import conforamaWireframes from "../images/conforama/conforama-wireframe.png";
import conforamaHF from "../images/conforama/conforama-high.png";

function Conforama() {
  return (
    <div className="project-page-container">
      <div className="prj-header-img">
        <img src={conforamaHeader} alt="conf-header" />
      </div>
      <div className="prj-header-img-mobile">
        <img src={conforamaHeader} alt="conf-mobile-header" />
      </div>
      <div className="prj-info-head">
        <h1>Rethinking the Ecommerce User Flow.</h1>
        <p>
          Conforama is opening a new marketplace hub within their brand
          offerings and needs to carry out a complete design of the pages to be
          incorporated. These designs are mainly focused on the purchase user
          journey and include the Product Page and Checkout. The designs must be
          carried out taking into account the usability of the marketplace
          within the Conforama website and will be adapted to Conforama's brand
          styles.
          <br />
          <br />
          General requirements:
          <br />
          <br />
          · Follow Mirakl Best Practices
          <br />
          <br />
          · Conduct usability testing with potential users
          <br />
          <br />
          · UX/UI design of all pages of the marketplace
          <br />
          <br />
          · Maintain current web styles
          <br />
          <br />· Take the opportunity to improve usability of the website.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>PAIN POINTS</h3>
        </div>
        <h2>Analysis of the pain points of the checkout process</h2>
        <p>
          In Paint Points we analyze the current User Flow to identify areas of
          improvement. Each step in the process has been taken into
          consideration, as well as the actions users may take. The following
          indicators act as a guideline to how parts of the current interface
          are working and show whether a visual solution is needed.
          <br />
          <br />
          We analyze the entire checkout process, pointing out:
          <br />
          <br />
          <b>1. Pain point:</b> This indicator mark areas of contention for
          users when trying to complete a task. Each of these are specific paint
          points where a user may have significant trouble to interact with the
          current design. Any areas marked as a paint point should be
          redesigned.
          <br />
          <br />
          <b>2. Stress point:</b> Yellow points are for areas where the concept
          is in line with recommended practices, but the design is unclear or
          could be improved. Here users are able to achieve their goal, but with
          some effort. As the current design is not helping the user these areas
          should be reconsidered.
          <br />
          <br />
          <b>3. Successful Design:</b> Here the designed item is working well
          and users are able to achieve their intended goal. Depending on the
          overall project however parts of this specific interface may need to
          be updated.
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaBasket} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>HEURISTIC EVALUATION</h3>
        </div>
        <h2>
          Testing the usability using the 10 rules of Heuristic Evaluation.
        </h2>
        <p>
          After analysing the current flow of Conforma's Checkout we have worked
          on a User flow where the main objectives are:
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaHeuristic} alt="cf-site-map" />
      </div>
      <div className="prj-info-head">
        <h1>Rethinking the Ecommerce User Flow.</h1>
        <p>
          Conforama is opening a new marketplace hub within their brand
          offerings and needs to carry out a complete design of the pages to be
          incorporated. These designs are mainly focused on the purchase user
          journey and include the Product Page and Checkout. The designs must be
          carried out taking into account the usability of the marketplace
          within the Conforama website and will be adapted to Conforama's brand
          styles.
          <br />
          <br />
          General requirements:
          <br />
          <br />
          · Follow Mirakl Best Practices
          <br />
          <br />
          · Conduct usability testing with potential users
          <br />
          <br />
          · UX/UI design of all pages of the marketplace
          <br />
          <br />
          · Maintain current web styles
          <br />
          <br />· Take the opportunity to improve usability of the website.
        </p>
      </div>
      <div className="prj-info-container">
        <div>
          <h3>USER FLOW</h3>
        </div>
        <h2>Re-organizing the information in 3 steps</h2>
        <p>
          All information will be re-structured based on 3 steps with three
          individual pages to better organise and make the flow easier and more
          intuitive:
          <br />
          <br />
          <b>1.Basket:</b> In this page the user will be able to access all
          information concerning the product. Change of colour, quantity, add
          guarantees, remove product or add products.
          <br />
          <br />
          <b>2. Shipping:</b> All the information about shipping. The three
          options: home delivery, shop collection and Click&Drive. Collection of
          old furniture. If any of thses options are not available in this
          purchase they will not be shown.
          <br />
          <br />
          <b>3. Checkout:</b> All payment options available to the user.
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaUserflow} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>BENCHMARKING</h3>
        </div>
        <h2>The best practices in the sector</h2>
        <p>
          After analysing both direct competitors and large ecommerce companies
          in the sector and, above all, taking into account Mirakl's Best
          Practices, we have included several examples of visual benchmarking:
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaBM} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>WIREFRAMES & USABILITY TESTING</h3>
        </div>
        <h2>The challenge of simplicity</h2>
        <p>
          We work on high-fidelity wireframes to be able to test users and focus
          above all on the functionality of each page and the user flow. All the
          information had to be easy to find, easy system to find and add items
          and complete the purchase. We worked with the prototyped wireframes
          and the user test was outsourced along with a guideline that the user
          had to follow.
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaWireframes} alt="cf-site-map" />
      </div>
      <div className="prj-info-container">
        <div>
          <h3>HIGH-FIDELITY</h3>
        </div>
        <h2>The final product</h2>
        <p>
          The results of the user test helped us to relocate information such as
          product guarantees and add pop-ups with complementary information. The
          objective: not to lose any type of information related to the product
          without overloading the interface with information.
        </p>
      </div>
      <div className="site-map">
        <img src={conforamaHF} alt="cf-site-map" />
      </div>
      <div className="prj-info-container" style={{ marginBottom: "50px" }}>
        <div>
          <h3>LEARNINGS</h3>
        </div>
        <ul>
          <li>
            Learn to simplify a very complex process. The weight and the size of
            a font is the best option to priorizaze and rank information.
          </li>
          <li>Learn to synthesize, to structure information.</li>
          <li>Learn to work with a user testing, priorizaze the feedback.</li>
          <li>
            Learn to prototype a very complex process with a lot of flows,
            pop-ups and steps.
          </li>
          <li>Iterate and try it again.</li>
        </ul>
      </div>
    </div>
  );
}

export default Conforama;
